/* eslint-disable */
import $ from "jquery";
/*
 * Copyright 2020 © REALESYS™ - REALESYS DIGITAL SERVICES LLC AND ITS SUBSIDIARIES
 * Created by Luís Felipe de Souza SEPT 2012
 * Updated by Luís Felipe de Souza JUL 2020
 * dataHandler JavaScript Library Version ES6.000.011 Beta 1 of APR 25 2016 for https://realesys.com
 * DEPENDENCIES:
 *  - JQUERY 3.1.0
 */
"use strict";
const COURRIER_URI        = `https://courrier.realesys.com/`
const COURRIER_API_KEY    = 'YnJha2lvX2VzdHJhdGVnaWErZGVzaWdu' 
const COURRIER_GROUP_UID  = 'ZXF1aXBlQGJyYWtpby5jb20uYnI='
export const COURRIER_API = `${COURRIER_URI}api/v1/send/${COURRIER_API_KEY}/${COURRIER_GROUP_UID}`;
export const dh6 = {
    // FOR VALIDATION
    /*
     * Validade any value and return true if IS NULL, typeof 'undefined', (blank space) ' '
     */
    is_null: function (v) {
        return !((v !== ' ')&&(v !== '')&&(v !== null)&&(v !== 'undefined')&&(typeof v !== 'undefined'));
    },
    /*
     * Check if any value is NULL then return option t for true and f for false in case f is not set return v
     */
    if_null: function(v,scope,t,f = 'self') {
        // check if v is null
        if(dh6.is_null(v)){
            // return value for true
            return t;
        } else {
            // v is not null, is v an object?
            if(dh6.is_object(v)){
                // set var (v) to returnvar (r)
                let r = v;
                // let's look for values within the defined scope of the object
                for(let p in scope)
                {
                    // is scope[position] within the object returnvar (r)?
                    if(!dh6.is_null(r[scope[p]]))
                    {
                        // replace r with contents within scope
                        r = r[scope[p]];
                    }
                    else
                    {
                        // return value for true as it is in fact null within the scope
                        return t;
                    }
                }
                // return self (v/r) or value set as default for false (f)?
                if(f === 'self')
                    return r;
                else
                    return f;
            } else {
                // v is not null but it's not an object!
                // return self (v/r) or value set as default for false (f)?
                if(f === 'self')
                    return v;
                else
                    return f;
            }
        }
    },
    /*
     * Validade any variable to check if typeof is 'object' and NOT NULL, typeof 'undefined', (blank space) ' ', 0 as string
     */
    is_object: function(v) {
        return ((!dh6.is_null(v))&&(typeof v === 'object'))
    },
    /*
     * Validade any variable to check if typeof is 'boolean' and NOT NULL, typeof 'undefined', (blank space) ' ', 0 as string
     */
    is_bool: function(v)
    {
        return ((!dh6.is_null(v))&&(typeof v === 'boolean'));
    },
    /*
     * Validade any variable to check if typeof is 'string' and NOT NULL, typeof 'undefined', (blank space) ' ', 0 as string
     */
    is_string: function (v)
    {
        return ((!dh6.is_null(v))&&(typeof v === 'string'));
    },
    /*
     * Validade any variable to check if typeof is 'string' and NOT NULL, typeof 'undefined', (blank space) ' ', 0 as string
     */
    is_numeric: function (v)
    {
        const asInt = parseInt(v);
        const regex = /^[0-9]*$/;
        return ((!isNaN(asInt * 8)) && (!isNaN(asInt - 8)) && (!isNaN(asInt + 8)) && regex.test(v));
    },
    has_escape: function(v){

    },
    is_name: function (v) {
        let name = decodeURIComponent(v);
        const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        if(!dh6.is_null(name)) {
            return regex.test(name);
        } else {
            return false;
        }
    },
    is_email: function (v) {
        let address = decodeURIComponent(v);
        const email_regex1 = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        const email_regex2 = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        if(!dh6.is_null(address)) {
            return (email_regex1.test(address) && email_regex2.test(address));
        } else {
            return false;
        }
    },
    /*
     * checks is integer is within iValues set as parameters
     * if is 'greaterThan' && 'lessThan' parameters return true
     */
    is_withinRange: function (v,greaterThan,lessThan)
    {
        if (dh6.is_numeric(v))
            return ((v > greaterThan) && (v < lessThan));
        else
            return false;
    },
    // VERIFICATION FOR HTML ELEMENTS AND SELECTORS
    /*
    * Switches btn status
    */
    btn_feedback: function(btn,status,exc) {
        if(!dh6.is_null(btn['id']))
        {
            let html;
            let outline = dh6.if_null(btn,['id','outline'],'','outline-');
            switch (status)
            {
                case 'disable' || 0:
                    $(btn['id']).prop("disabled", true); // Disable button
                    break;
                case 'enable' || 1:
                    $(btn['id']).prop("disabled", false); // Enable button
                    break;
                case 'success' || 2:
                    dh6.btn_remove_status(btn['id']);
                    $(btn['id']).addClass("btn-" + outline + "success");
                    // change label if a label was provided as btn['btn-danger']['label']
                    html = " <span> " + dh6.if_null(btn,['btn-success','label'],' SUCCCESS ') + " " + (exc || '') + " </span> ";
                    html += " <span class='fas fa-"+ dh6.if_null(btn,['btn-success','icon'],'check') + "'></span> ";
                    $(btn['id']).html(html);
                    break;
                case 'fail' || 3:
                    dh6.btn_remove_status(btn['id']);
                    $(btn['id']).addClass("btn-" + outline + "danger");
                    // change label if a label was provided as btn['btn-danger']['label']
                    html = " <span> " + dh6.if_null(btn,['btn-danger','label'],' FAIL ') + " " + (exc || '') + " </span> ";
                    html += " <span class='fas fa-"+ dh6.if_null(btn,['btn-danger','icon'],'times') + "'></span> ";
                    $(btn['id']).html(html);
                    break;
                case 'load' || 4:
                    $(btn['id']).addClass("btn-" + outline + "success");
                    $(btn['id']).prop("disabled", true); // Disable button
                    // change label to loading
                    html = " <span class='spin fas fa-" + dh6.if_null(btn,['btn-load','icon'],'circle-notch') + "'></span> ";
                    $(btn['id']).html(html);
                    break;
                case 'reset' || 5:
                    $(btn['id']).prop("disabled", false); // Disable button
                    break;
                default:
                    dh6.btn_remove_status(btn['id']);
                    $(btn['id']).addClass("btn-" + outline + "warning");
                    // change label if a label was provided as btn['btn-danger']['label']
                    html = " <span> " + dh6.if_null(btn,['btn-warning','label'],' TRY AGAIN ') + " " + (exc || '') + " </span> ";
                    html += " <span class='fas fa-" + dh6.if_null(btn,['btn-warning','icon'],'repeat') + "'></span> ";
                    // change html of the button to reflect status
                    $(btn['id']).html(html);
                    break;
            }
        }
    },
    /*
     * Remove all styling on bootstrap 4 buttons
     */
    btn_remove_status: function(btnId) {
        $(btnId).removeClass("btn-success btn-warning btn-danger btn-outline-success btn-outline-warning btn-outline-danger");
        $(btnId).removeClass("btn-primary btn-outline-primary btn-secondary btn-outline-secondary btn-info btn-outline-info");
        $(btnId).removeClass("btn-light btn-outline-light btn-dark btn-outline-dark");
    },
    /*
     * Reset verification classes
     */
    unvalidate: function (element) {
        $(element).removeClass('is-valid');
        $(element).removeClass('is-invalid');
    },
    /*
     * Removes is-invalid class and adds is-valid class
     */
    make_valid: function (element) {
        $(element).addClass('is-valid');
        $(element).removeClass('is-invalid');
    },
    /*
     * Removes is-valid class and adds is-invalid class
     */
    make_invalid: function (element) {
        $(element).addClass('is-invalid');
        $(element).removeClass('is-valid');
    },
    /*
     * verifies if given element has # followed by name
     */
    is_element_id: function (element)
    {
        if(dh6.is_string(element))
            return (!(element.indexOf("#") === -1));
    },
    /*
     * getElementId() preppends # ot id if not yet set and returns it
     */
    get_element_id: function (str)
    {
        if(dh6.is_element_id(str))
            return str;
        else
            return '#' + str;
    },
    /*
     * Get element if it exists either with name or id passed as "name"
     */
    get_element: function (name) {
        // check if element exists either with name or id passed as "name"
        if(!dh6.is_null(document.getElementsByName(name))) {
            return document.getElementsByName(name);
        } else if (!dh6.is_null(document.getElementById(dh6.get_element_id(name)))) {
            return document.getElementById(dh6.get_element_id(name));
        }
    },
    /*
     * Get value of attributes for input name
     */
    get_element_attr_assoc: function (name,attr) {
        let element = dh6.get_element(name);
        //
        let inputAttr = {};
        if(dh6.is_object(attr)) {
            for (let i = 0, len = attr.length; i < len; ++i) {
                if( attr[i] === 'label' || attr[i] === 'aria-label') {
                    if(!dh6.is_null($(element).parent().find('label').text()))
                        inputAttr['label'] = $(element).parent().find('label').text();
                    else if(!dh6.is_null($(element).attr(attr[i])))
                        inputAttr['label'] = $(element).attr(attr[i]);
                }
                else {
                    if(!dh6.is_null($(element).attr(attr[i])))
                        inputAttr[attr[i]] = $(element).attr(attr[i]);
                }
            }
            return inputAttr;
        }
    },
    /*
     * HTML 5 validation using attributes
     */
    input_validate: function(input_data,input_name) {
        let element = dh6.get_element(input_name);
        dh6.unvalidate(element);
        let validation_result = '';
        let t = 0,v = 0;
        if(dh6.is_object(input_data)){
            for (const k in input_data) {
                if ((input_data.hasOwnProperty(k))) {
                    if (!dh6.is_null(k)) {
                        switch (k) {
                            case 'min' :
                                t++;
                                v++;
                                break;
                            case 'max' :
                                t++;
                                v++;
                                break;
                            case 'step' :
                                t++;
                                v++;
                                break;
                            case 'minlength' :
                                if(input_data['val'].length >=  input_data['minlength'])
                                    v++;
                                t++;
                                break;
                            case 'maxlength' :
                                if(input_data['val'].length <=  input_data['maxlength'])
                                    v++;
                                t++;
                                break;
                            case 'pattern' :
                                t++;
                                v++;
                                break;
                            case 'required' :
                                t++;
                                if(!dh6.is_null(input_data['val']))
                                    v++;
                                break;
                            case 'type' :
                                switch (input_data[k]) {
                                    case 'email':
                                        t++;
                                        if(dh6.is_email(input_data['val']))
                                            v++;
                                        break;
                                    case 'text' || 'password' || 'textarea':
                                        t++;
                                        if(dh6.is_string(input_data['val']) && (input_data['val'].length > 1)) {
                                            v++;
                                        }
                                        break;
                                    case 'number':
                                        t++;
                                        if(dh6.is_numeric(input_data['val']))
                                            v++;
                                        break;
                                    default:
                                        break;
                                }
                                break;
                            default:
                                break;
                        }
                        validation_result += k + ' : ' + input_data[k] + ' ';
                    }
                }
            }
            // console.log(validation_result);
            // check if passed all tests
            if(t === v) {
                if (!dh6.is_null(input_data['val']))
                {
                    dh6.make_valid(element);
                    return true;
                } else {
                    return false;
                }

            } else {
                dh6.make_invalid(element);
                return false;
            }

        }
    },
    /*
     * Organize raw data from form serialization into object and validate fields
     */
    form_validate: function(raw_data) {

        let data = {}; // declare the data array
        data['contentType'] = "json";
        data['processData'] = false;
        data['structuredRequest'] = {}; // declare the structured data sub-array
        data['request'] = raw_data;
        let attr = [
            'max','min', 'step',
            'maxlength','minlength',
            'pattern',
            'required',
            'type', 'data-group', 'data-type', 'data-label',
            'aria-label','placeholder','label'
        ];
        let validFlag = 0, tests = 0;
        // split by &
        let rawDataObj = raw_data.split('&');
        // loop through rawDataObj
        for (let i = 0, len = rawDataObj.length; i < len; ++i) {
            // split by =
            let temp = rawDataObj[i].split('='); // split by =
            // reorganize raw data into sub-array
            data['structuredRequest'][temp[0]] = dh6.get_element_attr_assoc(temp[0],attr);
            data['structuredRequest'][temp[0]]['val'] = temp[1];
            //validation
            tests++;
            if(dh6.input_validate(data['structuredRequest'][temp[0]],temp[0]))
                validFlag++
        }
        // console.log('tests: ' + tests + ' passed: ' + validFlag);
        if(validFlag === tests)
            return data;
        else
            return false;
    },
    // POST REQUESTS
    form_send: function(f) {
        // Sanitize
        let url  = COURRIER_API; // get the url from the action on the form
        let rawdt = ($(f['id']).serialize()).replace(/%5C/g, '%26bsol%3B'); // replaces escape ( "\" ) character for html entity reference ( "&bsol;" )
        rawdt = rawdt.replace(/%22/g, '%5C%22'); // escapes double quotes
        let data = dh6.form_validate(rawdt); // turn all input fields into long string for get request
        // DEBUG DATA -----------
        // console.debug(data);
        // is data an object?
        if(dh6.is_object(data)) {
            $.post(url, JSON.stringify(data), function (r) {
                //do if result is ok
                // console.log(r);
                console.log('success!');
                if(!dh6.is_null(f['btn']))
                    dh6.btn_feedback(f['btn'],'success',(r.status || ''));
            }).fail(function (r) {
                //do if fails
                console.log(r);
                if(!dh6.is_null(f['btn']))
                    dh6.btn_feedback(f['btn'],'fail',(r.status || '500'));
            }).done(function () {
                setTimeout(function () {
                    if (!dh6.is_null(f['btn']))
                        $(f['btn']).prop("disabled", false); // Disable submit button until AJAX call is complete to prevent duplicate messages
                }, 1000)
            });
        } else {
            // is btn id defined? if so, enable it
            if(!dh6.is_null(f['btn']))
            {
                dh6.btn_feedback(f['btn'],'enable');
                dh6.btn_feedback(f['btn'],'warning','');
            }
            console.error('DATA IS NOT AN OBJECT');
        }
    },
    /*
     * Collect all info from a form and submit it after verification
     */
    form_submit: function(f) {
        // is btn id defined? if so, disable it
        if(!dh6.is_null(f['btn']))
            dh6.btn_feedback(f['btn'],'load');
        // recaptcha
        if(dh6.is_object(f['recaptcha'])){
            grecaptcha.ready(function() {
                grecaptcha.execute(f['recaptcha']['key'], {action: f['recaptcha']['action'] || 'Default' }).then(function(token) {
                    if (token) {
                        document.getElementById(f['recaptcha']['id'] || 'recaptcha' ).value = token;
                        dh6.form_send(f);
                    }
                });
            });
        } else {
            dh6.form_send(f);
        }
    },
};