<template>
  <div id="app bootstrapoverrides">
    <section class="mainnavbar"></section>
    <app-main></app-main>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppMain from './components/main.vue'
import AppFooter from './components/footer.vue'

export default {
  name: 'App',
  components: {
    AppMain,
    AppFooter
  },
  mounted() {
    
  }
}
</script>
<style>
  @import url("./assets/vendor/fa/css/all.min.css");
  @import url("./assets/css/app.css");
</style>

