import Vue from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"

Vue.config.productionTip = false

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAQfZqJTvzNu9AksDiCcCOLr0chiY72m6o",
  authDomain: "brakio.firebaseapp.com",
  projectId: "brakio",
  storageBucket: "brakio.appspot.com",
  messagingSenderId: "287147470841",
  appId: "1:287147470841:web:af5782555d7d6c1ba35728",
  measurementId: "G-17ZFMKNK2B"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

new Vue({
  render: h => h(App),
}).$mount('#app')

import "bootstrap/dist/js/bootstrap.js"