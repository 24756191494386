<template>
    <div id="contact-us" class="app-section pt-3 pb-3 w-100">
        <a id="contact-us"></a>
        <div class="container pb-5">
            <div class="row">
                <div class="col text-center section-title-wrapper p-5">
                    <h5 class="text-uppercase text-muted">
                        <span class="section-title"> Contate-nos </span>
                    </h5>
                </div>
            </div>
            <div class="row section-content">
                <div class="col-md-8 col-lg-7 pb-5 m-auto">
                    <form id="contactUs" data-title="CONTATO BRAKIO">
                        <div class="form-row mb-3">
                            <div id="nameinp" class="col">
                                <input v-model="name" id="name" name="name" type="text" placeholder="Nome"
                                    class="form-control " 
                                    data-label="Nome" 
                                    required="">
                                <div id="name-pf" class="valid-feedback">
                                </div>
                                <div id="name-nf" class="invalid-feedback feedback-icon">
                                    <span id="icon" class="fal fa-times"> </span>
                                    Informe seu nome e sobrenome.
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-3">
                            <div id="emailinp" class="col">
                                <input v-model="email" id="email" name="email" type="email" placeholder="Email"
                                    class="form-control " 
                                    data-label="Email"
                                    required="">
                                <div id="email-pf" class="valid-feedback">
                                </div>
                                <div id="email-nf" class="invalid-feedback feedback-icon">
                                    <span id="icon" class="fal fa-times"> </span>
                                    Informe seu endereço de email.
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-3">
                            <div id="companynameinp" class="col">
                                <input v-model="companyname" id="companyname" name="companyname" type="text" placeholder="Empresa"
                                    class="form-control " 
                                    data-label="Empresa">
                                <div id="companyname-pf" class="valid-feedback">
                                </div>
                                <div id="companyname-nf" class="invalid-feedback feedback-icon">
                                    <span id="icon" class="fal fa-times"> </span>
                                    Informe o nome da sua empresa.
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div id="dddinp" class="col-3">
                                <input v-model="areacode" 
                                    id="areacode" 
                                    name="areacode" 
                                    type="number" 
                                    placeholder="DDD"
                                    minlength="2"
                                    class="form-control " 
                                    required=""
                                >
                                <div id="areacode-pf" class="valid-feedback">
                                </div>
                                <div id="areacode-nf" class="invalid-feedback feedback-icon">
                                    <span id="icon" class="fal fa-times"> </span>
                                    Informe apenas números e um código de área válido.
                                </div>
                            </div>
                            <div id="telinp" class="col-9">
                                <input v-model="tel" id="tel" name="tel" type="number" placeholder="Telefone"
                                    class="form-control "
                                    minlength="5" 
                                    maxlength="15" 
                                    data-group="tel" 
                                    data-label="Telefone" 
                                    required="">
                                <div id="tel-pf" class="valid-feedback">
                                </div>
                                <div id="tel-nf" class="invalid-feedback feedback-icon">
                                    <span id="icon" class="fal fa-times"> </span>
                                    Informe apenas números e um telefone válido.
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-3">
                            <div id="subjinp" class="col">
                                <input v-model="subject" id="subject" name="subject" type="text" placeholder="Assunto"
                                    class="form-control " 
                                    minlength="4" maxlength="140"
                                    data-group="msg" 
                                    data-label="Assunto" 
                                    required="">
                                <div id="subject-pf" class="valid-feedback">
                                </div>
                                <div id="subject-nf" class="invalid-feedback feedback-icon">
                                    <span id="icon" class="fal fa-times"> </span>
                                    Informe o assunto com no máximo 140 caracteres.
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-3">
                            <div id="messageinp" class="col">
                                <textarea v-model="message" id="message" style="min-height:125px;" name="message"
                                    type="textarea" placeholder="Converse com a gente :D!"
                                    class="form-control " minlength="140" maxlength="999" data-group="msg"
                                    data-label="Mensagem"
                                    required=""
                                >
                                </textarea>
                                <div id="message-pf" class="valid-feedback">
                                </div>
                                <div id="message-nf" class="invalid-feedback feedback-icon">
                                    <span id="icon" class="fal fa-times"> </span>
                                    Insira uma mensagem com ao menos 140 caracteres.
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-3">
                            <div class="col-12 m-0 text-center">
                                <small>
                                    <small class="text-muted">
                                        This site is protected by reCAPTCHA <br> Google's
                                        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                                        <a href="https://policies.google.com/terms">Terms of Service</a>
                                        apply.
                                    </small>
                                </small>
                            </div>
                            <div id="recaptchav3" class="col">
                                <input id="recaptcha" name="recaptcha" type="hidden" class="form-control "
                                    required="">
                                <div id="recaptcha-pf" class="valid-feedback">
                                </div>
                                <div id="recaptcha-nf" class="invalid-feedback feedback-icon">
                                    <span id="icon" class="fal fa-times"> </span>
                                    <b>Prove you're human</b>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button id="contactUsBtn" @click="submit"
                                class="btn btn-brakio p-2 text-white float-end mr-2">
                                <span>
                                    &nbsp;
                                    Enviar
                                    <small>
                                        <span id="contactUsBtnIcon" class="fal fa-paper-plane fas"> </span>
                                    </small>
                                    &nbsp;
                                </span>
                            </button>
                        </div>
                        <div v-if="debug" class="form-group">
                            <button id="contactUsBtnWarning" @click="submit"
                                class="btn btn-outline-warning float-right mr-2">
                                <span>
                                    Tentar Novamente
                                    <span id="icon" class="fal fa-repeat fas"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { dh6 } from "@/services/dh6"

export default {
    data() {
        return {
            debug: false,

            name: "",
            email: "",
            companyname: "",
            areacode: "",
            tel: "",
            subject: "",
            message: "",
        
            // name: "Tester 6449",
            // email: "tester6449@realesys.com",
            // companyname: "Testing Company LTD",
            // areacode: "790",
            // tel: "647478475",
            // subject: "Lorem ipsum.",
            // message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque interdum rutrum sodales. Nullam mattis fermentum libero, non volutpat.",
            
            form: {
                'id': "#contactUs",
                'recaptcha': {
                    'id': 'recaptcha',
                    'key': '6LdDxiEaAAAAABsnXi2qqIv4B2SimDH6RYsMVvRJ',
                    'action': 'contactus',
                },
                'btn': {
                    'id': "#contactUsBtn",
                    'label': "Enviar",
                    'outline': true,
                    'icon': "paper-plane",
                    'iconId': "#contactUsBtnIcon",
                    'btn-success': {
                        'icon': "check",
                        'label': "Sucesso"
                    },
                    'btn-warning': {
                        'icon': "repeat",
                        'label': "Tentar Novamente"
                    },
                    'btn-danger': {
                        'icon': "times",
                        'label': "Erro"
                    }
                },
            }
        }
    },
    methods: {
        submit() {
            try {
                dh6.form_submit(this.form);
            } catch (e) {
                console.log(e)
            }
        }
    }
}
</script>

<style>

</style>