<template>
  <section class="mainfooter">
        <footer id="footer">
            <div class="footer-top">
                <div class="container">
                    <div class="row pt-5 pb-5">
                        <div class="col d-flex align-items-center pt-1 pb-1 m-auto">
                            <div class="footer-info m-auto force-max-width">
                                <div class="row flex-wrap m-auto pb-5">
                                    <div class="brand">
                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            viewBox="536 224 128 128" enable-background="new 536 224 128 128"
                                            xml:space="preserve">
                                            <g>
                                                <path fill="#7B8388"
                                                    d="M599.6,268.2c2.7-1,4.7-2.5,5.9-3.9c2.7-2.9,4.1-7,4.1-11.7c0-4.7-1.4-8.8-4.1-11.8
        c-2.1-2.3-6-5.1-12.7-5.1h-8.4h-6.7v50.8h7.9v-17.2h5.8l12.8,17.2h9L599.6,268.2L599.6,268.2z M585.7,262.2v-19.3h7.1
        c3.3,0,5.8,0.9,7.3,2.7c1.9,2.1,2.3,4.9,2.3,7c0,2-0.3,4.9-2.3,6.9c-1.6,1.8-4.1,2.7-7.3,2.7L585.7,262.2L585.7,262.2z"></path>
                                                <path fill="#7B8388" d="M538,235.7h17.3c5.2,0,8.2,2.1,9.8,3.9c2.1,2.3,3.2,5.4,3.2,9.1c0,3.6-1.1,6.8-3.2,9
        c-0.1,0.1-0.1,0.1-0.2,0.2c1.7,0.8,2.9,1.9,3.8,2.9c2.4,2.6,3.7,6.4,3.7,10.5c0,4.2-1.3,8-3.7,10.6c-1.8,2.1-5.4,4.6-11.4,4.6H538
        V235.7L538,235.7z M557.2,263.4h-11.5V280h11.5c3,0,5.1-0.8,6.5-2.4c1.7-1.8,1.7-4.3,1.7-6.2c0-1.8-0.2-3.6-1.9-5.5
        C562,264.3,560.2,263.4,557.2,263.4L557.2,263.4z M545.8,242.9v14.4h9c2.4,0,4.2-1,5.4-2.2c1.4-1.5,1.7-3.6,1.7-5.1
        c0-1.5-0.3-3.3-1.7-4.9c-1.2-1.3-2.7-2.1-5.2-2.1L545.8,242.9L545.8,242.9z"></path>
                                                <polygon fill="#7B8388" points="545.9,289.4 545.9,307.4 560.2,324 576,342.6 566.1,342.6 545.9,319.6 545.9,342.6 538,342.6
        538,289.4 	"></polygon>
                                                <polygon fill="#7B8388"
                                                    points="559,307.4 553.6,301.2 562.7,289.4 572.7,289.4 	">
                                                </polygon>
                                                <rect x="588.2" y="289.4" fill="#7B8388" width="7.9" height="53.2">
                                                </rect>
                                                <path fill="#7B8388" d="M635.4,342.6c3.6,0,7.1-0.7,10.4-2.1c3.1-1.4,6-3.3,8.5-5.7c2.4-2.4,4.3-5.2,5.7-8.5
        c1.4-3.2,2.1-6.7,2.1-10.3c0-3.6-0.7-7.1-2.1-10.4c-1.4-3.1-3.2-6-5.7-8.5c-2.5-2.4-5.3-4.3-8.5-5.7c-3.3-1.4-6.8-2.1-10.4-2.1
        s-7.1,0.7-10.3,2.1c-3.2,1.4-6,3.2-8.5,5.7c-2.4,2.5-4.4,5.3-5.7,8.5c-1.4,3.3-2.1,6.8-2.1,10.4s0.7,7.1,2.1,10.3
        c1.4,3.2,3.3,6,5.7,8.5c2.4,2.4,5.2,4.4,8.5,5.7C628.3,341.9,631.8,342.6,635.4,342.6L635.4,342.6z M635.4,297.7
        c4.9,0,9.4,1.9,12.9,5.4c3.4,3.4,5.4,8,5.4,12.9c0,4.9-1.9,9.5-5.4,12.9c-3.4,3.4-8,5.4-12.9,5.4c-4.9,0-9.4-1.9-12.9-5.4
        c-3.4-3.4-5.4-8-5.4-12.9c0-4.9,1.9-9.4,5.4-12.9C626,299.7,630.6,297.7,635.4,297.7z"></path>
                                                <polygon fill="#7B8388"
                                                    points="626.3,286.6 617.8,286.6 640,233.4 662,286.6 653.3,286.6 639.8,252 	">
                                                </polygon>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    <div class="copyright">
                        <div class="">
                            <div class="f-r m-auto  d-block d-lg-inline d-xl-inline">
                                ©
                                <a class="" href="https://brakio.com.br" target="_blank">
                                    <span class="f-r">BRAKIO</span>
                                </a>
                                +
                                <a class="" href="https://realesys.com" target="_blank">
                                    <span class="f-r">REAL</span><b class="f-bolder">E</b><span
                                        class="f-b">SYS</span>
                                </a>
                                . All Rights Reserved.
                            </div>
                            <div class="m-auto d-inline">
                                Developed and powered by
                                <a class="" href="https://realesys.com" target="_blank">
                                    <span class="f-r">REAL</span><b class="f-bolder">E</b><span
                                        class="f-b">SYS</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <a id="backToTop" @click="backToTop" class="back-to-top" style="display: none;">
            <div class="back-to-top-icon">
                <span id="icon" class="fal fa-arrow-up"> </span>
            </div>
        </a>
    </section>
</template>

<script>
import $ from "jquery"
import 'jquery-ui-bundle'
import 'jquery-ui-bundle/jquery-ui.css'

export default {    
    name: 'AppFooter',
    methods: {
        backToTop() {
            try {
                $('html, body').animate({
                    scrollTop: 0
                }, 1500, 'easeInOutExpo', function() {
                    // $(".nav-menu ul:first li:first").addClass('active');
                });
            } catch {
                return false
            }
        }
    },
    mounted() {
        // Back to top button and fixed navbar
        $(window).scroll(function() {
            if ($(this).scrollTop() > 400) {
                $('#backToTop').fadeIn('slow');
                $("#navOnScroll").fadeIn('slow');
            } else {
                $('#backToTop').fadeOut('slow');
                $("#navOnScroll").fadeOut('slow');
            }
        });
    }
}
</script>

<style>

</style>