<template>
  <div>
    <section class="maincontent">
        <main>
            <header>
                <div id="header">
                    <div class="container h-100">
                        <div class="row h-80">
                            <div class="col-12 m-auto pt-5">
                                <div class="brand m-auto" style="width: 150px; height: 50px;">
                                    <svg version="1.2" baseProfile="tiny" id="Camada_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 703.5 241.5" xml:space="preserve">
                                        <g>
                                            <g>
                                                <path fill-rule="evenodd" d="M174.1,100c7.4-2.6,12.5-6.8,15.8-10.5c7.2-7.9,11-18.9,11-31.4c0-12.7-3.9-23.7-11-31.6
  c-5.5-6.3-16-13.6-34-13.6h-22.4h-18v136.4h21.3v-46.1h15.6l34.4,46.1h24.1L174.1,100L174.1,100z M136.8,84V32.2h19.1
  c8.8,0,15.4,2.4,19.7,7.2c5.1,5.5,6.1,13.3,6.1,18.8c0,5.3-0.9,13.1-6.1,18.6c-4.2,4.8-10.9,7.4-19.7,7.4H136.8z">
                                                </path>
                                                <path fill-rule="evenodd" d="M8.8,12.9h46.4c13.9,0,22,5.7,26.2,10.5c5.5,6.1,8.5,14.6,8.5,24.4c0,9.6-3,18.1-8.5,24.2
  c-0.2,0.2-0.3,0.4-0.5,0.6c4.5,2.2,7.9,5.1,10.2,7.7c6.4,7.1,9.9,17,9.9,28.3c0,11.4-3.5,21.4-9.9,28.5
  c-5,5.6-14.4,12.2-30.6,12.2H8.8V12.9L8.8,12.9z M60.5,87.2H29.6v44.4h30.8c8,0,13.6-2.2,17.4-6.5c4.6-5,4.5-11.6,4.5-16.6
  c0-4.8-0.5-9.8-5.1-14.7C73.4,89.5,68.4,87.2,60.5,87.2L60.5,87.2z M29.6,32.2v38.5h24.3c6.5,0,11.3-2.5,14.4-6
  c3.8-4,4.4-9.7,4.4-13.6c0-4-0.8-9-4.6-13.1c-3.2-3.6-7.2-5.7-13.8-5.7H29.6z"></path>
                                                <polygon fill-rule="evenodd" points="384.4,6.6 384.4,54.7 422.8,99.4 465.1,149.3 438.5,149.3 384.4,87.5 384.4,149.3
  363.1,149.3 363.1,6.6 		"></polygon>
                                                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse"
                                                    x1="448.8784" y1="12.172" x2="411.2244" y2="40.691">
                                                    <stop offset="0" style="stop-color:#FD954D"></stop>
                                                    <stop offset="1" style="stop-color:#FD1213"></stop>
                                                </linearGradient>
                                                <polygon fill-rule="evenodd" fill="url(#SVGID_1_)"
                                                    points="456.2,6.6 429.3,6.6 403.4,40 419.5,54.8 		"></polygon>
                                                <rect x="497.9" y="6.6" fill-rule="evenodd" width="21.3"
                                                    height="142.6"></rect>
                                                <path fill-rule="evenodd" d="M624.6,149.3c9.6,0,19-1.9,27.9-5.6c8.5-3.6,16-8.8,22.7-15.4c6.5-6.5,11.5-14,15.2-22.7
  c3.6-8.7,5.6-18.1,5.6-27.7c0-9.6-1.9-19-5.6-27.9c-3.6-8.5-8.6-16-15.2-22.7c-6.7-6.5-14.2-11.5-22.7-15.2
  c-8.8-3.6-18.3-5.6-27.9-5.6c-9.6,0-19,1.9-27.7,5.6c-8.7,3.6-16.2,8.6-22.7,15.2c-6.5,6.7-11.7,14.2-15.4,22.7
  c-3.6,8.9-5.6,18.3-5.6,27.9c0,9.6,1.9,19,5.6,27.7c3.6,8.7,8.8,16.2,15.4,22.7c6.5,6.5,14,11.7,22.7,15.4
  C605.5,147.4,614.9,149.3,624.6,149.3L624.6,149.3z M624.6,28.9c13.1,0,25.4,5.2,34.6,14.4c9.2,9.2,14.4,21.5,14.4,34.6
  c0,13.1-5.2,25.4-14.4,34.6c-9.2,9.2-21.5,14.4-34.6,14.4c-13.1,0-25.4-5.2-34.6-14.4c-9.2-9.2-14.4-21.5-14.4-34.6
  c0-13.1,5.2-25.4,14.4-34.6C599.2,34.1,611.5,28.9,624.6,28.9z"></path>
                                                <polygon fill-rule="evenodd"
                                                    points="245.7,149.3 223,149.3 282.4,6.6 341.6,149.3 318.2,149.3 282,56.7 		">
                                                </polygon>
                                            </g>
                                            <g>
                                                <path fill-rule="evenodd" d="M84.4,208.6c0,7.5,4,13.9,11.9,13.9c2,0,6,0.2,10.9-5.9l-2.5-2.4c-3.8,5-7,4.8-8.4,4.8
  c-5.2,0-8-4.4-7.8-10.8h18.6v-1.9c0-1.9-0.8-12.1-11-12.1C89,194.1,84.4,200.6,84.4,208.6L84.4,208.6z M96.1,197.6
  c4.7,0,6.3,4,6.8,6.8H89C90.1,200.6,92.4,197.6,96.1,197.6z"></path>
                                                <path fill-rule="evenodd" d="M118,201.8c0-3.7,3.1-4.3,4.6-4.3c2.4,0,4.6,0.9,5.9,2.3l2.7-2.6c-1.9-1.9-5-3.1-8.6-3.1
  c-2.6,0-8.7,1.1-8.7,7.8c0,5.1,4.4,6.6,8.6,7.8c2.9,0.9,6.1,1.9,6.1,4.7c0,3.1-2.7,4.6-5.4,4.6c-3.2-0.1-6.4-1.7-7.9-4.4l-2.9,2.3
  c2.3,3.7,6.6,5.5,10.6,5.5c4.8,0,9.6-2.6,9.6-8.1c0-5.8-4.6-7.2-9.1-8.5C120.8,205,118,204.1,118,201.8z"></path>
                                                <path fill-rule="evenodd" d="M137.3,198h3.5v15.9c0,5.1,1.5,8.5,5.6,8.5c3.1,0,5.5-0.8,7.7-3.1l-2.5-2.4c-1.2,1.2-2.5,1.9-4.1,1.9
  c-2.6,0-2.6-3.1-2.6-4.9V198h6.5v-3.4h-6.5v-9.8h-4.2v9.8h-3.5V198z"></path>
                                                <path fill-rule="evenodd" d="M159,194.6v27.2h4.1v-17c1.4-3,3.9-6.8,7.1-6.8c2.3,0,3.5,1.6,3.5,1.6l2.9-2.8c0,0-2.3-2.7-6.6-2.7
  c-2.8,0-5.1,1.3-6.9,4.3v-3.7H159z"></path>
                                                <path fill-rule="evenodd" d="M181.8,201.7c0,0.1,6.6-3.8,10.8-3.8c2.8,0,3.1,2.9,3.1,3.9v3.1c-1.2,0.3-3.3,0.6-6.9,1.1
  c-8.4,1.2-9.8,5.4-9.8,8.9c0,6.9,6.6,7.4,7.7,7.4c3.7,0,6.9-1.7,9.3-4.2c0.4,1.7,1.4,3.3,3.8,4.2l2.7-2.5
  c-2.7-1.3-2.7-3.3-2.7-3.5v-14.6c0-2.6-0.9-7.8-6.6-7.8c-5.5,0-11.3,3.3-13.1,4.3C180.1,198.5,181.8,201.7,181.8,201.7
  L181.8,201.7z M186.6,219c-0.7-0.1-3.5-0.6-3.5-4c0-2.3,0.8-4.6,6.2-5.4c3.2-0.5,5-0.7,6.4-1v5.8C193.7,216.8,190,219.2,186.6,219
  z"></path>
                                                <path fill-rule="evenodd" d="M206.4,198h3.5v15.9c0,5.1,1.5,8.5,5.6,8.5c3.1,0,5.5-0.8,7.7-3.1l-2.5-2.4c-1.2,1.2-2.5,1.9-4.1,1.9
  c-2.6,0-2.6-3.1-2.6-4.9V198h6.5v-3.4H214v-9.8h-4.2v9.8h-3.5V198z"></path>
                                                <path fill-rule="evenodd"
                                                    d="M226.5,208.6c0,7.5,4,13.9,11.9,13.9c2,0,6,0.2,10.9-5.9l-2.5-2.4c-3.8,5-7,4.8-8.4,4.8
  c-5.2,0-8-4.4-7.8-10.8h18.6v-1.9c0-1.9-0.8-12.1-11-12.1C231.1,194.1,226.5,200.6,226.5,208.6L226.5,208.6z M238.2,197.6
  c4.7,0,6.3,4,6.8,6.8h-13.8C232.3,200.6,234.6,197.6,238.2,197.6L238.2,197.6z M242.7,184.5l-6.5,7.2h3.4l8.2-7.2H242.7z"></path>
                                                <path fill-rule="evenodd" d="M260.4,202.7c0-4,4.2-5.5,6.6-5.5c2.5,0,6.6,1.5,6.6,5.5c0,5.2-4.1,6.3-6.6,6.3
  C264.5,208.9,260.4,208.3,260.4,202.7L260.4,202.7z M257,215.8c0,1.7,1.1,2.6,2.5,3.2c-2.4,1.7-4.1,3.3-4.2,7.7
  c0,4.7,5.6,8.7,11.9,8.7c9.6,0,13.9-5.1,13.9-11.1c0-6.6-7.6-7.8-14-8.1c-1.1,0-5.6,0-5.9-0.4c0-1.4,1.3-2.8,2.9-4
  c1,0.2,2.1,0.3,3,0.3c4.1,0,10.7-1.6,10.7-9.5c0-3.2-1.7-5.3-3.9-6.7c1.2-0.5,2.6-0.8,4-0.8c0.9,0,1.7,0.1,2.3,0.3l0.7-3.4
  c-1-0.3-2-0.5-3-0.5c-2.6,0-5.4,1.1-7.5,2.9c-1.2-0.3-2.3-0.5-3.3-0.5c-4.1,0-10.7,2.5-10.7,8.7c0,4.4,1.9,6.7,4.2,8
  C258.5,212.3,257,214.1,257,215.8L257,215.8z M267.2,231.9c-3.8,0-7.8-2.3-7.8-5.1c0-4.2,1.7-5.4,4.1-6.9c1.3,0,2.6,0,3.6,0
  c4.5,0.3,10,0.8,10,4.5C277,228.4,273.9,231.9,267.2,231.9z"></path>
                                                <path fill-rule="evenodd" d="M287.4,221.8h4.1v-27.2h-4.1V221.8L287.4,221.8z M286.9,186.3c0,1.5,1.1,2.6,2.6,2.6
  c1.4,0,2.5-1.1,2.5-2.6c0-1.4-1.1-2.5-2.5-2.5C288,183.8,286.9,184.9,286.9,186.3z"></path>
                                                <path fill-rule="evenodd" d="M301.6,201.7c0,0.1,6.6-3.8,10.8-3.8c2.8,0,3.1,2.9,3.1,3.9v3.1c-1.2,0.3-3.3,0.6-6.9,1.1
  c-8.4,1.2-9.8,5.4-9.8,8.9c0,6.9,6.6,7.4,7.7,7.4c3.7,0,6.9-1.7,9.3-4.2c0.4,1.7,1.4,3.3,3.8,4.2l2.7-2.5
  c-2.7-1.3-2.7-3.3-2.7-3.5v-14.6c0-2.6-0.9-7.8-6.6-7.8c-5.5,0-11.3,3.3-13.1,4.3C299.9,198.5,301.6,201.7,301.6,201.7
  L301.6,201.7z M306.3,219c-0.7-0.1-3.5-0.6-3.5-4c0-2.3,0.8-4.6,6.2-5.4c3.2-0.5,4.9-0.7,6.4-1v5.8
  C313.5,216.8,309.8,219.2,306.3,219z"></path>
                                                <polygon fill-rule="evenodd" points="354.2,221.8 354.2,209.9 366.5,209.9 366.5,206.6 354.2,206.6 354.2,194.6 350.9,194.6
  350.9,206.6 339.2,206.6 339.2,209.9 350.9,209.9 350.9,221.8 		"></polygon>
                                                <path fill-rule="evenodd" d="M394.3,222.3c4.3,0,7.7-2,10.3-5c0.2,1.5,0.7,4,4.2,5.3l2.6-2.7c-3.2-1-2.9-4.5-2.9-4.5v-34h-4.1
  v16.5c-2.8-2.4-5.8-3.7-8.5-3.7c-7.1,0-11.5,5.4-11.5,14.6C384.4,215.9,388.3,222.2,394.3,222.3L394.3,222.3z M394.3,218.2
  c-3.5-0.3-5.6-4.5-5.6-9.5c0-6.7,2.9-10.6,7.3-10.6c2.6,0,5.7,1.8,8.5,4.5v9C402.8,214.2,399.6,218.6,394.3,218.2z">
                                                </path>
                                                <path fill-rule="evenodd" d="M416,208.6c0,7.5,4,13.9,11.9,13.9c2,0,6,0.2,10.9-5.9l-2.5-2.4c-3.8,5-7,4.8-8.4,4.8
  c-5.2,0-8-4.4-7.8-10.8h18.6v-1.9c0-1.9-0.8-12.1-11-12.1C420.6,194.1,416,200.6,416,208.6L416,208.6z M427.7,197.6
  c4.7,0,6.3,4,6.8,6.8h-13.8C421.8,200.6,424,197.6,427.7,197.6z"></path>
                                                <path fill-rule="evenodd" d="M449.6,201.8c0-3.7,3.1-4.3,4.6-4.3c2.4,0,4.6,0.9,5.9,2.3l2.7-2.6c-1.9-1.9-5-3.1-8.6-3.1
  c-2.6,0-8.7,1.1-8.7,7.8c0,5.1,4.4,6.6,8.6,7.8c2.9,0.9,6.1,1.9,6.1,4.7c0,3.1-2.7,4.6-5.4,4.6c-3.2-0.1-6.4-1.7-7.9-4.4l-2.9,2.3
  c2.3,3.7,6.6,5.5,10.6,5.5c4.8,0,9.6-2.6,9.6-8.1c0-5.8-4.6-7.2-9.1-8.5C452.4,205,449.6,204.1,449.6,201.8z"></path>
                                                <path fill-rule="evenodd" d="M471.4,221.8h4.1v-27.2h-4.1V221.8L471.4,221.8z M470.9,186.3c0,1.5,1.1,2.6,2.6,2.6
  c1.4,0,2.5-1.1,2.5-2.6c0-1.4-1.1-2.5-2.5-2.5C472,183.8,470.9,184.9,470.9,186.3z"></path>
                                                <path fill-rule="evenodd" d="M488.1,202.7c0-4,4.2-5.5,6.6-5.5c2.5,0,6.6,1.5,6.6,5.5c0,5.2-4.1,6.3-6.6,6.3
  C492.3,208.9,488.1,208.3,488.1,202.7L488.1,202.7z M484.7,215.8c0,1.7,1.1,2.6,2.5,3.2c-2.4,1.7-4.1,3.3-4.2,7.7
  c0,4.7,5.6,8.7,11.9,8.7c9.6,0,13.9-5.1,13.9-11.1c0-6.6-7.6-7.8-14-8.1c-1.1,0-5.6,0-5.9-0.4c0-1.4,1.3-2.8,2.9-4
  c1,0.2,2.1,0.3,3,0.3c4.1,0,10.7-1.6,10.7-9.5c0-3.2-1.7-5.3-3.9-6.7c1.2-0.5,2.6-0.8,4-0.8c0.9,0,1.7,0.1,2.3,0.3l0.7-3.4
  c-1-0.3-2-0.5-3-0.5c-2.6,0-5.4,1.1-7.5,2.9c-1.2-0.3-2.3-0.5-3.3-0.5c-4.1,0-10.7,2.5-10.7,8.7c0,4.4,1.9,6.7,4.2,8
  C486.2,212.3,484.7,214.1,484.7,215.8L484.7,215.8z M494.9,231.9c-3.8,0-7.8-2.3-7.8-5.1c0-4.2,1.7-5.4,4.1-6.9c1.3,0,2.6,0,3.6,0
  c4.5,0.3,10,0.8,10,4.5C504.7,228.4,501.6,231.9,494.9,231.9z"></path>
                                                <path fill-rule="evenodd" d="M518.8,221.8v-17.1c2.6-2.7,6.9-6.5,11.9-6.5c1.5,0,2.1,1.7,2.1,4.3v19.3h4.1v-19.3
  c0-5.2-1.8-8.4-6.3-8.4c-4.5,0-8.9,2.9-11.9,5.8v-5.3h-4.2v27.2H518.8z"></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div class="d-flex hero-content-center h-100" style="margin-top: -8rem;">
                                <div class="row align-self-center" style="max-width: 100%">
                                    <div class="col-11 col-md-4">
                                        <h5
                                            class="text-uppercase pl-3 pl-md-0 float-left float-md-right text-end">
                                            POTENCIALIZAR
                                            <br> IDEIAS NOS
                                        </h5>
                                    </div>
                                    <div class="col-11 m-auto m-md-0 col-md-6">
                                        <div class="text-left ml-sm-1 p-lg-0 m-lg-0"
                                            style="margin-top:5px; max-width: 75%">
                                            <img class="" src="/assets/m/i/inpiramotivamove.gif"
                                                alt="INSPIRA MOTIVA E MOVE" width="100%" height="auto">
                                        </div>
                                    </div>
                                    <div class="col-12 pt-5">
                                        <div class="col-md-10 col-lg-10 text-left f-l m-auto">
                                            <p>
                                                A BRAKIO está crescendo e estamos re-estruturando nosso site.
                                                <br>
                                                Em breve teremos novidades para você.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row hero-support-elements justify-content-center">
                            <div class="col-10 col-md-8 col-lg-6 text-center m-auto">
                                <a class="btn btn-brakio text-white btn-call-to-action" target="_blank"
                                    href="https://wa.me/5551980462110">
                                    <span>
                                        <span id="icon" class="fab fa-whatsapp fab" style="font-size:1.2em;">
                                        </span>
                                        &nbsp; Fale Conosco &nbsp;
                                        <span id="icon" class="fal fa-angle-right flashing"> </span>
                                    </span>
                                </a>
                                <br>
                                <br>
                                <p>
                                    Solicite nosso portfólio pelo Whatsapp ou via formulário de contato.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div id="about-us" class="app-section pt-3 pb-3 w-100">
                <a id="about-us"></a>
                <div class="container pb-5">
                    <div class="row">
                        <div class="col text-center section-title-wrapper p-5">
                            <h5 class="text-uppercase text-muted">
                                <span class="section-title"> Quem Somos </span>
                            </h5>
                        </div>
                    </div>
                    <div class="row section-content">
                        <div class="col">
                            <div class="row">
                                <div class="col-md-8 col-lg-7 text-justify f-l m-auto">
                                    <p>
                                        Uma equipe de design que pulsa a criatividade em busca de soluções. Geramos
                                        estratégias para agregar valor e expandir o potencial de negócios. Estamos
                                        há mais de dois anos no mercado potencializando ideias, apoiando empresas em
                                        mais de 14 estados do Brasil na área de criação de branding, criação de
                                        marca, criação de nome para marca, design gráfico, design de produto, design
                                        estratégico, UX design, UI design, Web design, design de interiores, design
                                        editorial, ilustração, marketing, publicidade, criação de sites e etc.
                                    </p>
                                    <p>
                                        Entre em contato para que possamos também apoiar a sua empresa.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <contact-form></contact-form>
    </section>
  </div>
</template>

<script>
import ContactForm from "./contactForm.vue"

export default {
  name: 'AppMain',
  components: {
      ContactForm
  }
}
</script>